<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Ventas por cobrar</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" class="mr-2" @click.prevent="update()">
					<v-icon left light small>mdi-reload</v-icon>
					Actualizar
				</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-tabs
				v-model="tab"
				background-color="primary"
				
				fixed-tabs
				centered
				dark
			>
				<v-tabs-slider></v-tabs-slider>

				<v-tab href="#tab-1">
					VENTAS EN SALÓN
					<!-- <v-icon>mdi-table</v-icon> -->
				</v-tab>

				<v-tab href="#tab-2">
					<!-- <v-badge
                        color="error"
                        content="5"
                    >
                        DELIVERY
                    </v-badge> -->
					DELIVERY
					<!-- <v-icon>mdi-bike</v-icon> -->
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item :key="1" :value="'tab-' + 1">
					<emb-data-table-query-builder :resource="resource">
						<tr slot="heading">
							<th width="5%" class="text-right">N°</th>
							<th class="text-right">PRE-CUENTA N°</th>
							<th>FECHA</th>
							<th>HORA</th>
							<th>MOZ@</th>
							<th>MESA</th>
							<th>CLEINTE</th>
							<th class="text-right">TOTAL</th>
							<th class="text-right">ACCIONES</th>
						</tr>
						<tr slot-scope="{ index, row }" :key="index">
							<td class="text-right">{{ index }}</td>
							<td class="text-right">{{ row.identifier }}</td>
							<td>{{ row.date_of_issue }}</td>
							<td>{{ row.time_of_issue }}</td>
							<td>{{ row.waiter }}</td>
							<td>{{ row.tble }}</td>
							<td>
								{{ row.customer }}
							</td>
							<td class="text-right">
								<strong>S/ {{ row.total }}</strong>
							</td>

							<td class="text-right mr-0 ml-0 pl-0 pr-0">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									:to="{ name: 'SaleReceivable', params: { id: row.id } }"
								>
									<v-icon>mdi-exit-to-app</v-icon>
								</v-btn>
							</td>
						</tr>
					</emb-data-table-query-builder>
				</v-tab-item> 
				<v-tab-item :key="2" :value="'tab-' + 2">
					<deliveries></deliveries>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</div>
</template>

<script>
import Deliveries from "./Component/DeliveryList.vue";
export default {
	data() {
		return {
			showDialog: false,
			resource: 'sales-receivables',
			recordId: null,
			records: [],
			tab: null,
		};
	},
	components: {
		Deliveries
	},
	methods: {
		update() {
			// this.$eventHub.$emit('reloadDataSaleReceivables', this.resource, reload)
			location.reload();
		},
	},
};
</script>
